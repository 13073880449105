import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/api';
import { api_url } from '../config';

const initialState = {
  coupons: [], // List of coupons
  loading: false,
  error: null,
  meta: {
    current_page: 1,
    last_page: 1,
    per_page: 15,
    total: 0
  }
};

// Fetch coupons 
export const fetchCoupons = createAsyncThunk(
  'coupons/fetchCoupons',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { auth: { token, id: userId, branch_id } } = getState();

      const response = await api.get(
        `${api_url}/v1/stripe/admin/${userId}/coupon-data`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 
        error.message || 
        'Failed to fetch coupons'
      );
    }
  }
);

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Coupons Cases
      .addCase(fetchCoupons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.coupons = action.payload.data || [];
        state.meta = action.payload.meta || state.meta;
      })
      .addCase(fetchCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.coupons = [];
      });
  },
});

export default couponsSlice.reducer;