import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ProductsTable from "./ProductsTable";
import {
    IoAddCircleOutline,
    IoCheckmarkDoneCircleOutline,
    IoCloudDone,
} from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { api_url } from "../../config";
import { MutatingDots } from "react-loader-spinner";
import axios from "axios";
import { VscError } from "react-icons/vsc";
import { RiErrorWarningFill } from "react-icons/ri";
import { fetchCoupons } from "../../Redux/CouponSlice";
import { BiCheck, BiChevronDown } from "react-icons/bi";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is required")
        .max(155, "Name must not exceed 155 characters"),
    description: Yup.string().required("Description is required"),
    price: Yup.string().typeError("").required("Price is required"),
    billable: Yup.string()
        .oneOf(
            ["monthly", "yearly", "quarterly", "semi-annually"],
            "Invalid billing period"
        )
        .required("Billing period is required"),
    app: Yup.string()
        .oneOf(
            [
                "pharmtrix-retail",
                "pharmtrix-wholesale",
                "labtrix",
                "centrix",
                "billtrix",
            ],
            "Invalid app type"
        )
        .required("App type is required"),
    features: Yup.string().required("Features are required"),
    branches_limit: Yup.string().required("Kindly input number of branches"),
    users_limit: Yup.string().required("Kindly input number of users"),
    stripe_product: Yup.string().required("Stripe product is required"),
    stripe_price: Yup.string().required("Stripe price is required"),
    apply_promo: Yup.string()
        .oneOf(["true", "false"], "Invalid promo selection")
        .required("Promo application status is required"),
    // stripe_promo_code: Yup.string().required('Coupon is required when applying promo'),
    discounted_amount: Yup.string()
        .required("Discounted amount is required when applying promo")
});

const AddProductForm = ({ products, fetchPlans, stripeData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { token, id } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedPrice, setSelectedPrice] = useState("");
    const [error, setError] = useState(null);
    const [status, setStatus] = useState("");
    const dispatch = useDispatch();
    const { coupons } = useSelector((state) => state.coupons);
    const [isCouponOpen, setIsCouponOpen] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);

    const handleSelect = (coupon) => {
        setSelectedCoupon(coupon);
        setIsCouponOpen(false);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        dispatch(fetchCoupons());
    }, [dispatch]);

    const watchedPrice = watch("price");

    const handleProductChange = (e) => {
        const productId = e.target.value;
        setSelectedProduct(productId);
        setSelectedPrice("");
        setValue("stripe_product", productId);
        setValue("price", "");

        const selectedProductObj = stripeData?.find(
            (product) => product.product.id === productId
        );

        if (selectedProductObj) {
            const appType = selectedProductObj.product.name
                .split(" ")[0]
                .toLowerCase();
            setValue("app", appType);
        }
    };

    const handlePriceChange = (e) => {
        const priceId = e.target.value;
        setSelectedPrice(priceId);
        setValue("stripe_price", priceId);

        // Find the selected price object and set the price
        const selectedPriceObj = selectedProductObj?.product.prices.find(
            (price) => price.id === priceId
        );
        if (selectedPriceObj) {
            setValue("price", selectedPriceObj.unit_amount);
        }
    };

    const selectedProductObj = stripeData?.find(
        (product) => product.product.id === selectedProduct
    );

    const AddPlan = async (data) => {
        
        
        try {
            setLoading(true);
            const planData = {
                ...data,
                stripe_promo_code: selectedCoupon.id,
                apply_promo: data.apply_promo === "true",
                // features: data.features.split(',').map((feature) => feature.trim()),
            };

            const response = await axios.post(
                `${api_url}/v1/admin/${id}/plans`,
                planData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            

            console.log("Plan added successfully:", response.data);
            setStatus("Plan added successfully");
            fetchPlans();
            setTimeout(() => {
                setStatus("");
                setIsOpen(false);
            }, 1500);
            reset();
        } catch (error) {
            setError(error?.response?.data?.error || "An error occurred");
            setTimeout(() => {
                setError("");
            }, 3500);
        } finally {
            setLoading(false);
        }
    };

    const toggleForm = () => {
        setIsOpen(!isOpen);
        reset();
        setSelectedProduct("");
        setSelectedPrice("");
    };

    return (
        <div>
            <div className="flex justify-end w-full">
                <button
                    onClick={toggleForm}
                    className="px-4 py-2 text-xs my-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none flex justify-center items-center gap-2"
                >
                    <IoAddCircleOutline size={20} />
                    Add Plan
                </button>
            </div>
            <div className="w-full overflow-auto max-h-[70vh] ">
                <ProductsTable
                    products={products}
                    fetchPlans={fetchPlans}
                    stripeData={stripeData}
                />
            </div>

            <div
                className={`fixed top-0 right-0 w-full md:w-1/3 h-full bg-white shadow-lg transform transition-transform duration-300 ${isOpen ? "translate-x-0" : "translate-x-full"
                    }`}
            >
                <div className="p-6 h-full overflow-auto scrollbar-thin scrollbar-webkit">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-medium text-gray-900">Add a plan</h2>
                        <button
                            onClick={toggleForm}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <IoIosCloseCircleOutline size={20} />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(AddPlan)} className="space-y-4">
                        {loading && ( // Display loader while loading
                            <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0 z-20">
                                <MutatingDots
                                    visible={true}
                                    height="100"
                                    width="100"
                                    color="#4fa94d"
                                    secondaryColor="#4fa94d"
                                    radius="12.5"
                                    ariaLabel="mutating-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        )}
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="stripeProduct"
                            >
                                Product
                            </label>
                            <select
                                id="stripeProduct"
                                value={selectedProduct}
                                onChange={handleProductChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                <option value="">Select a product</option>
                                {stripeData?.map((product) => (
                                    <option key={product.product.id} value={product.product.id}>
                                        {product.product.name}
                                    </option>
                                ))}
                            </select>
                            {errors.stripe_product && (
                                <p className="text-red-600 text-sm">
                                    {errors.stripe_product.message}
                                </p>
                            )}
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Package Name
                            </label>
                            <input
                                type="text"
                                {...register("name")}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.name && (
                                <p className="text-red-600 text-sm">{errors.name.message}</p>
                            )}
                        </div>
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                App Type
                            </label>
                            {/* <select
                                {...register('app')}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <option value="">Select App Type</option>
                                <option value="pharmtrix">Pharmtrix</option>
                                <option value="labtrix">Labtrix</option>
                                <option value="centrix">Centrix</option>
                                <option value="billtrix">Billtrix</option>
                            </select> */}
                            <input
                                type="text"
                                readOnly
                                {...register("app")}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.app && (
                                <p className="text-red-600 text-sm">{errors.app.message}</p>
                            )}
                        </div>

                        {selectedProduct && (
                            <div className="mb-4">
                                <label
                                    className="block text-gray-700 text-sm font-bold mb-2"
                                    htmlFor="stripePrice"
                                >
                                    Stripe Price
                                </label>
                                <select
                                    id="stripePrice"
                                    value={selectedPrice}
                                    onChange={handlePriceChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    <option value="">Select a price</option>
                                    {selectedProductObj?.product.prices.map((price) => (
                                        <option key={price.id} value={price.id}>
                                            {price.unit_amount} {price.currency.toUpperCase()} /{" "}
                                            {price.recurring.interval_count}{" "}
                                            {price.recurring.interval}
                                        </option>
                                    ))}
                                </select>
                                {errors.stripe_price && (
                                    <p className="text-red-600 text-sm">
                                        {errors.stripe_price.message}
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Description
                            </label>
                            <textarea
                                {...register("description")}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.description && (
                                <p className="text-red-600 text-sm">
                                    {errors.description.message}
                                </p>
                            )}
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Billing Period
                            </label>
                            <select
                                {...register("billable")}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <option value="">Select Billing Period</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Annually</option>
                                <option value="semi-annually">Semi-annually</option>
                                <option value="quarterly">Quarterly</option>
                            </select>
                            {errors.billable && (
                                <p className="text-red-600 text-sm">
                                    {errors.billable.message}
                                </p>
                            )}
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Features
                            </label>
                            <input
                                type="text"
                                {...register("features")}
                                placeholder="Enter features separated by commas"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.features && (
                                <p className="text-red-600 text-sm">
                                    {errors.features.message}
                                </p>
                            )}
                        </div>

                        <input type="hidden" {...register("stripe_product")} />
                        <input type="hidden" {...register("stripe_price")} />
                        {/* <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">Price</label>
                            <input
                                type="number"
                                {...register('price')}
                                readOnly
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
                            />
                            {errors.price && <p className="text-red-600 text-sm">{errors.price.message}</p>}
                        </div> */}

                        <div className="relative w-full">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Coupon
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    {...register("coupon")}
                                    onClick={() => setIsCouponOpen(!isCouponOpen)}
                                    placeholder="Select coupon"
                                    value={selectedCoupon?.name || ""}
                                    readOnly
                                    className="w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer"
                                />
                                <BiChevronDown
                                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none"
                                    size={20}
                                />
                            </div>

                            {isCouponOpen && (
                                <ul
                                    className="absolute z-10 w-full bg-white border rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto"
                                >
                                    {coupons.map((coupon) => (
                                        <li
                                            key={coupon.id}
                                            className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center group relative"
                                            onClick={() => {
                                                handleSelect(coupon);
                                                setIsCouponOpen(false);
                                            }}
                                        >
                                            <div>
                                                <div className="font-medium">{coupon.name}</div>
                                                <div className="text-sm text-gray-500">{coupon.id}</div>
                                            </div>
                                            {selectedCoupon?.id === coupon.id && (
                                                <BiCheck
                                                    className="text-indigo-600 opacity-0 group-hover:opacity-100"
                                                    size={20}
                                                />
                                            )}
                                        </li>
                                    ))}
                                    {coupons.length === 0 && (
                                        <li className="px-3 py-2 text-gray-500 text-center">
                                            No coupons available
                                        </li>
                                    )}
                                </ul>
                            )}

                            {errors.coupon && (
                                <p className="text-red-600 text-sm mt-1">
                                    {errors.coupon.message}
                                </p>
                            )}
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Discounted Amount
                            </label>
                            <input
                                type="string"
                                {...register("discounted_amount")}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.discounted_amount && (
                                <p className="text-red-600 text-sm">
                                    {errors.discounted_amount.message}
                                </p>
                            )}
                        </div>

                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                Apply Promo
                            </label>
                            <select
                                {...register("apply_promo")}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select>
                            {errors.apply_promo && (
                                <p className="text-red-600 text-sm">
                                    {errors.apply_promo.message}
                                </p>
                            )}
                        </div>
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                No. of branches
                            </label>
                            <input
                                type="number"
                                {...register("branches_limit")}
                                placeholder=""
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.features && (
                                <p className="text-red-600 text-sm">
                                    {errors.branches_limit.message}
                                </p>
                            )}
                        </div>
                        <div className="space-y-2">
                            <label className="block text-sm font-medium text-gray-700">
                                No. of Users
                            </label>
                            <input
                                type="number"
                                {...register("users_limit")}
                                placeholder=""
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            />
                            {errors.features && (
                                <p className="text-red-600 text-sm">
                                    {errors.users_limit.message}
                                </p>
                            )}
                        </div>

                        <div className="flex justify-end space-x-2">
                            <button
                                type="button"
                                onClick={toggleForm}
                                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={loading}
                                className="px-3 py-1 text-xs bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none flex justify-center items-center gap-1"
                            >
                                {loading ? (
                                    "Adding..."
                                ) : (
                                    <>
                                        <IoCheckmarkDoneCircleOutline size={20} />
                                        Add
                                    </>
                                )}
                            </button>
                        </div>
                        <div>
                            {status ? (
                                <div>
                                    <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs flex items-center gap-1 justify-center border-l-4 border-green-900 ">
                                        <IoCloudDone size={18} className="text-green-900" />
                                        {status}
                                    </p>
                                </div>
                            ) : error ? (
                                <div>
                                    <p className="bg-red-300 font-lufga rounded-md shadow-md px-2 py-1 text-sm flex items-center gap-1 justify-center border-l-4 border-red-900">
                                        <RiErrorWarningFill size={15} className="text-red-900" />
                                        {typeof error === "string"
                                            ? error
                                            : Object.keys(error).map((key) =>
                                                Array.isArray(error[key]) ? error[key][0] : error[key]
                                            )}
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddProductForm;
