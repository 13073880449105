import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import subscribedPlansReducer from './Redux/SubscribedPlansSlice';
import couponsReducer from './Redux/CouponSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    subscribedPlans: subscribedPlansReducer,
    coupons: couponsReducer

  },
});

export default store;
