import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IoCheckmarkDoneCircleOutline, IoCloudDone } from 'react-icons/io5';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { api_url } from '../../config';
import { MutatingDots } from 'react-loader-spinner';
import axios from 'axios';
import { RiErrorWarningFill } from 'react-icons/ri';
import { BiCheck, BiChevronDown } from 'react-icons/bi';
import { fetchCoupons } from '../../Redux/CouponSlice';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').max(155, 'Name must not exceed 155 characters'),
    description: Yup.string().required('Description is required'),
    billable: Yup.string().oneOf(['monthly', 'yearly', 'quarterly', 'semi-annually'], 'Invalid billing period').required('Billing period is required'),
    app: Yup.string(),
    features: Yup.string().required('Features are required'),
    branches_limit: Yup.string().required('Kindly input number of branches'),
    users_limit: Yup.string().required('Kindly input number of users'),
    stripe_price: Yup.string().required('Stripe price is required'),
    price: Yup.number().required('Price is required'),
    apply_promo: Yup.string()
        .oneOf(["true" || 1, "false" || 0], "Invalid promo selection")
        .required("Promo application status is required"),
    // stripe_promo_code: Yup.string().required('Coupon is required when applying promo'),
    discounted_amount: Yup.string()
        .required("Discounted amount is required when applying promo")
});

const EditPlanForm = ({ plan, onClose, onUpdate, fetchPlans, stripeData }) => {
    const { token, id } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('');
    const { coupons } = useSelector((state) => state.coupons);
    const [isCouponOpen, setIsCouponOpen] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const dispatch = useDispatch();

    const handleSelect = (coupon) => {
        setSelectedCoupon(coupon);
        setIsCouponOpen(false);
    };
    useEffect(() => {
        dispatch(fetchCoupons());
    }, [dispatch]);


    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: plan?.name || '',
            description: plan?.description || '',
            billable: plan?.billable || '',
            app: plan?.app || '',
            features: plan?.features ? plan.features.join(', ') : '',
            users_limit: plan?.users_limit || '',
            branches_limit: plan?.branches_limit || '',
            stripe_price: plan?.stripe_price || '',
            price: plan?.price || '',
            apply_promo: plan?.apply_promo === 1 ? "true" : "false",
            discounted_amount: plan?.discounted_amount || '',
            coupon: plan?.stripe_promo_code || ''
        },
    });

    const selectedProductObj = stripeData?.find(product => product?.product?.id === plan?.stripe_product);

    useEffect(() => {
        reset({
            name: plan?.name || '',
            description: plan?.description || '',
            billable: plan?.billable || '',
            app: plan?.app || '',
            features: plan?.features ? plan.features.join(', ') : '',
            users_limit: plan?.users_limit || '',
            branches_limit: plan?.branches_limit || '',
            stripe_price: plan?.stripe_price || '',
            price: plan?.price || '',
            apply_promo: plan?.apply_promo === 1 ? "true" : "false",
            discounted_amount: plan?.discounted_amount || '',
            coupon: plan?.stripe_promo_code || ''
        });
    }, [plan, reset]);



    const handlePriceChange = (e) => {
        const priceId = e.target.value;
        const selectedPriceObj = selectedProductObj?.product.prices.find(price => price.id === priceId);
        if (selectedPriceObj) {
            setValue('stripe_price', priceId);
            setValue('price', selectedPriceObj.unit_amount);
            setValue('billable', selectedPriceObj.recurring.interval);
        }
    };

    const updatePlan = async (data) => {
        try {
            setLoading(true);
            const response = await axios.put(
                `${api_url}/v1/admin/${id}/plans/${plan.id}`,
                {
                    ...data,
                    stripe_promo_code: selectedCoupon.id,
                    apply_promo: data.apply_promo === "true",
                    // features: data.features.split(',').map((feature) => feature.trim()),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setStatus('Plan updated successfully');
            setTimeout(() => onClose(), 1500);
            reset();
            fetchPlans();
        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('')
            }, 3500);
        } finally {
            setLoading(false);
            setTimeout(() => {
                setStatus('')
            }, 2500);
        }
    };

    return (
        <div className="bg-white p-5 rounded-lg shadow-xl w-full h-full overflow-auto">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-gray-900">Edit plan</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <IoIosCloseCircleOutline size={20} />
                </button>
            </div>

            <form onSubmit={handleSubmit(updatePlan)} className="space-y-4">
                {loading && (
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0 z-20">
                        <MutatingDots
                            visible={true}
                            height="100"
                            width="100"
                            color="#4fa94d"
                            secondaryColor="#4fa94d"
                            radius="12.5"
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                )}

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Product</label>
                    <input
                        type="text"
                        value={plan?.stripe_product || ''}
                        disabled
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
                    />
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Package Name</label>
                    <input
                        type="text"
                        {...register('name')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.name && <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>}
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="stripePrice">
                        Stripe Price
                    </label>
                    <select
                        id="stripePrice"
                        {...register('stripe_price')}
                        onChange={handlePriceChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <option value="">Select a price</option>
                        {selectedProductObj?.product.prices.map((price) => (
                            <option key={price.id} value={price.id}>
                                {price.unit_amount} {price.currency.toUpperCase()} / {price.recurring.interval_count} {price.recurring.interval}
                            </option>
                        ))}
                    </select>
                    {errors.stripe_price && <p className="text-red-600 text-sm">{errors.stripe_price.message}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Price</label>
                    <input
                        type="text"
                        {...register('price')}
                        readOnly
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
                    />
                    {errors.price && <p className="mt-1 text-sm text-red-600">{errors.price.message}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">App Type</label>
                    <input
                        type="text"
                        readOnly
                        {...register('app')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 bg-gray-100"
                    />
                    {errors.app && <p className="mt-1 text-sm text-red-600">{errors.app.message}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Description</label>
                    <textarea
                        {...register('description')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.description && <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Billing Period</label>
                    <select
                        {...register('billable')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Annually</option>
                        <option value="semi-annually">Semi annually</option>
                        <option value="quarterly">Quarterly</option>
                    </select>
                    {errors.billable && <p className="mt-1 text-sm text-red-600">{errors.billable.message}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">Features</label>
                    <input
                        type="text"
                        {...register('features')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.features && <p className="mt-1 text-sm text-red-600">{errors.features.message}</p>}
                </div>
                <div className="relative w-full">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Coupon
                    </label>
                    <div className="relative">
                        <input
                            type="text"
                            {...register("coupon")}
                            onClick={() => setIsCouponOpen(!isCouponOpen)}
                            placeholder="Select coupon"
                            value={selectedCoupon?.name || ""}
                            readOnly
                            className="w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 cursor-pointer"
                        />
                        <BiChevronDown
                            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none"
                            size={20}
                        />
                    </div>

                    {isCouponOpen && (
                        <ul
                            className="absolute z-10 w-full bg-white border rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto"
                        >
                            {coupons.map((coupon) => (
                                <li
                                    key={coupon.id}
                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center group relative"
                                    onClick={() => {
                                        handleSelect(coupon);
                                        setIsCouponOpen(false);
                                    }}
                                >
                                    <div>
                                        <div className="font-medium">{coupon.name}</div>
                                        <div className="text-sm text-gray-500">{coupon.id}</div>
                                    </div>
                                    {selectedCoupon?.id === coupon.id && (
                                        <BiCheck
                                            className="text-indigo-600 opacity-0 group-hover:opacity-100"
                                            size={20}
                                        />
                                    )}
                                </li>
                            ))}
                            {coupons.length === 0 && (
                                <li className="px-3 py-2 text-gray-500 text-center">
                                    No coupons available
                                </li>
                            )}
                        </ul>
                    )}

                    {errors.coupon && (
                        <p className="text-red-600 text-sm mt-1">
                            {errors.coupon.message}
                        </p>
                    )}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                        Discounted Amount
                    </label>
                    <input
                        type="string"
                        {...register("discounted_amount")}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.discounted_amount && (
                        <p className="text-red-600 text-sm">
                            {errors.discounted_amount.message}
                        </p>
                    )}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">
                        Apply Promo
                    </label>
                    <select
                        {...register("apply_promo")}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                        <option value={"false" || 0}>No</option>
                        <option value={"true" || 1}>Yes</option>
                    </select>
                    {errors.apply_promo && (
                        <p className="text-red-600 text-sm">
                            {errors.apply_promo.message}
                        </p>
                    )}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">No. of branches</label>
                    <input
                        type="number"
                        {...register('branches_limit')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.branches_limit && <p className="text-red-600 text-sm">{errors.branches_limit.message}</p>}
                </div>

                <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-700">No. of Users</label>
                    <input
                        type="number"
                        {...register('users_limit')}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    {errors.users_limit && <p className="text-red-600 text-sm">{errors.users_limit.message}</p>}
                </div>

                <div className="flex justify-end space-x-2 mt-4">
                    <button
                        type="button"
                        onClick={onClose}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Update
                    </button>
                </div>

                {status && (
                    <div className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs flex items-center gap-1 justify-center border-l-4 border-green-900">
                        <IoCloudDone size={18} className='text-green-900' />
                        {status}
                    </div>
                )}

                {error && (
                    <div className="bg-red-300 font-lufga rounded-md shadow-md px-2 py-1 text-sm flex items-center gap-1 justify-center border-l-4 border-red-900">
                        <RiErrorWarningFill size={15} className='text-red-900' />
                        {typeof error === 'string' ? error : (
                            Object.keys(error).map((key) => (
                                Array.isArray(error[key]) ? error[key][0] : error[key]
                            ))
                        )}
                    </div>
                )}
            </form>
        </div>
    );
};

export default EditPlanForm;